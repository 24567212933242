








import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { EventStores } from '@/store/events';
import CustomEventForm from '@/components/custom-events/CustomEventForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    CustomEventForm,
  },
})
export default class AddEvents extends Vue {
  eventStore = EventStores;

  unsubscribe: () => void = () => {};

  handleSubmit(payload: any) {
    this.eventStore.createEvent(payload);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'event/CREATE_EVENT_SUCCESS') {
        this.$router.replace('/events');
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
